@use '@/styles/utils/mixins.scss' as *;

.bgGrey {
  background-color: $kiaCharlestonColor;
}

.bgGreyDark {
  background-color: $description;
}

.headingElement {
  position: relative;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    &.titleLg {
      font-size: 36px;
      line-height: 1.2;
    }
    &.titleMd {
      font-size: 24px;
      line-height: 36px;
    }
    &.titleXs {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
  .titleText {
    font-weight: 400;
    font-size: 16px;
  }
}

.footerTop {
  background-color: $kiaCharlestonColor;
  color: $kiaPolarWhite;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-width: 100%;

  @include tab() {
    display: block;
    padding-left: 0rem;
  }

  @include mob() {
    display: block;
    padding-left: 0rem;
  }

  .footerCta {
    background-color: $kiaCharlestonColor;
    padding: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    .ctaBtn {
      padding: 15px 30px;
      @media screen and (min-width: 1120px) {
        margin-left: 40px;
      }
    }

    @media screen and (max-width: 1120px) {
      display: block;
      padding: 30px;
      .headingElement {
        margin-bottom: 20px;
      }
    }

    @media screen and (max-width: 991.98px) {
      padding: 40px 24px;
      display: flex;
      text-align: left;
      .headingElement {
        margin-bottom: 0px;
      }
      .ctaBtn {
        margin-left: 40px;
      }
    }

    @media screen and (max-width: 767px) {
      display: block;
      .ctaBtn {
        margin-left: 0px;
      }
      .headingElement {
        margin-bottom: 20px;
      }
    }
  }

  .footerSocialWrapper {
    background-color: $description;
    padding: 40px 80px;
    margin-left: auto;
    display: flex;
    align-items: center;
    @include tab() {
      padding: 30px 24px;
    }
    @media screen and (max-width: 567px) {
      justify-content: center;
      padding: 30px 15px;
    }
    .footerSocialLinkWrap {
      display: flex;
      align-items: center;
      margin: 0px -8px;
      @media screen and (max-width: 567px) {
        margin: 0px -5px;
        justify-content: center;
      }
      @media screen and (max-width: 480px) {
        flex-wrap: wrap;
      }

      .footerSocialLink {
        padding: 5px 8px;
        @media screen and (max-width: 567px) {
          padding: 5px;
        }
      }
    }
    .socialLink {
      background-color: $kiaCharlestonColor;
      border-radius: 100%;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      &:hover {
        background-color: $kiaMidnightBlack;
      }
    }
    .socialLinkLogo {
      width: 24px;
      height: 24px;
    }
    .linkedInLogo {
      width: 24px;
      height: 24px;
    }
    a:focus {
      box-shadow: none;
    }
  }
}

.headingElement {
  h3 {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 600;
  }
}

.titleText {
  font-size: 14px;
}

.popiaNotice {
  margin-top: 20px;
  font-size: 12px;
  color: $kiaMidnightBlack;

  .popiaLink {
    margin-top: 20px;
    font-size: 14px;
    display: inline-flex;
    font-weight: 600;
    color: $kiaMidnightBlack;
    text-decoration: underline;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    &:hover {
      text-decoration: none;
    }
  }
}
